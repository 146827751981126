export default [
  {
    path: '/doanh-nghiep/doanh-nghiep-vi-pham/danh-sach',
    component: () => import('@/views/management-business/bussiness-violation/pages/List.vue'),
    name: 'business-violation',
    meta: {
      pageTitle: 'Tổ chức vi phạm',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
        },
        {
          text: 'Tổ chức vi phạm',
          active: true,
        },
      ],
    },
  },

  // công văn đến của sở
  {
    path: '/doanh-nghiep/cong-van-den-so/danh-sach',
    component: () => import('@/views/management-business/document-in-department/pages/List.vue'),
    name: 'document-in-department',
    meta: {
      pageTitle: 'Công văn đến',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
        },
        {
          text: 'Công văn đến',
          active: true,
        },
      ],
    },
  },
  // Công văn đi của sở
  {
    path: '/doanh-nghiep/cong-van-di-so/danh-sach',
    component: () => import('@/views/management-business/document-departmnet/pages/List.vue'),
    name: 'document-department',
    meta: {
      pageTitle: 'Công văn đi',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
        },
        {
          text: 'Công văn đi',
          active: true,
        },
      ],
    },
  },

  // Công văn đến của doanh nghiệp
  {
    path: '/doanh-nghiep/cong-van-den-doanh-nghiep/danh-sach',
    component: () => import('@/views/management-business/document-in-bussiness/pages/List.vue'),
    name: 'document-in-bussiness',
    meta: {
      pageTitle: 'Công văn đến',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
        },
        {
          text: 'Công văn đến',
          active: true,
        },
      ],
    },
  },
  // Công văn đi của doanh nghiệp
  {
    path: '/doanh-nghiep/cong-van-di-doanh-nghiep/danh-sach',
    component: () => import('@/views/management-business/document-bussiness/pages/List.vue'),
    name: 'document-bussiness',
    meta: {
      pageTitle: 'Công văn đi',
      breadcrumb: [
        {
          text: 'Doanh nghiệp',
        },
        {
          text: 'Công văn đi',
          active: true,
        },
      ],
    },
  },
]
