export default [
  {
    path: '/nguoi-dung/quan-li-don-vi-cap-phong/danh-sach',
    name: 'education-department',
    component: () => import('@/views/management-user/education-department/pages/List.vue'),
    meta: {
      parent: 'education-department',
      pageTitle: 'Đơn vị cấp phòng',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Đơn vị cấp phòng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/quan-li-don-vi-cap-phong/them-tu-tep',
    name: 'education-import-file',
    component: () => import('@/views/management-user/education-department/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'education-department',
      pageTitle: 'Đơn vị cấp phòng',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
          to: '/nguoi-dung/quan-li-don-vi-cap-phong/danh-sach',
        },
        {
          text: 'Đơn vị cấp phòng',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/quan-li-don-vi-cap-huyen/danh-sach',
    name: 'province-department',
    component: () => import('@/views/management-user/province-department/pages/List.vue'),
    meta: {
      parent: 'province-department',
      pageTitle: 'Đơn vị cấp phòng',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Đơn vị cấp huyện',
          active: true,
        },
      ],
    },
  },
  {
    path: '/nguoi-dung/quan-li-don-vi-cap-huyen/them-tu-tep',
    name: 'province-department-import',
    component: () => import('@/views/management-user/province-department/pages/components/ListImportFile.vue'),
    meta: {
      parent: 'province-department',
      pageTitle: 'Đơn vị cấp phòng',
      breadcrumb: [
        {
          text: 'Quản lí đơn vị',
        },
        {
          text: 'Đơn vị cấp huyện',
          active: true,
        },
      ],
    },
  },
]
