export default {
  FECTH_DATA_LIST: '/Bussiness/bussiness',
  FETCH_DATA_ID: 'Bussiness/bussiness/',
  DATA_BUSINESS_TYPE: '/BussinessType/get-combobox-bussiness-type',
  DATA_OFFICAL_PROFILE_COMBOBOX: 'OfficalProfile/get-combobox-offical-profile',
  FETCH_USER_ID: 'OfficalProfile/officalProfiles/',
  LIST_PROVINCES: 'OfficalProfile/provinces', // lấy danh sách tỉnh thành phố
  FECTH_DISTRICTS: 'OfficalProfile/districts/', // lấy danh sách huyện
  FECTH_WARDS: 'OfficalProfile/wards/', // lấy dnah sách xã
  ADD_BUSINESS: 'Bussiness/bussinesses',
  CHECK_CODEST: 'Bussiness/existCode',
  CHECK_EMAILST: 'Bussiness/existCode', // xem lại
  EDITST: 'Bussiness/bussiness',
  DOWLOAD_FILE: 'Bussiness/generateTemplate',
  DOWNLOAD_EXCEL_ALL: 'Bussiness/export-excel',
  GET_API_EXCEL: 'Bussiness/import-bussiness-from-json',
  DELETEST: 'Bussiness/delete-bussiness',
  ACTIVE: 'Account/active-account/',
  ACTIVE_BUSINESS: 'Account/active-account-bussinesss',
  UN_ACTIVE: 'Account/disable-account/',
  LIST_BUSINESS_SECTOR: 'Bussiness/list-sector-in-bussiness',
  LIST_BUSINESS_NOT_SECTOR: 'Bussiness/list-sector-not-register',
  DELETE_BUSINESS_SECTOR: 'Bussiness/delete-sector-in-bussiness',
  ADD_BUSINESS_SECTOR: 'Bussiness/add-sector-to-bussiness',
  CHANGE_PASSWORD: 'Account/reissue-password',
  COMBOBOX_DEPARTMENT: 'OfficalProfile/get-combobox-department',
  BUSSINESS_TYPE: 'BussinessType/bussinessTypes ',
}
